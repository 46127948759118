body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.avatar{
  vertical-align: middle;
  max-height: 350px;
  max-width: 100%;
  border-radius: 50%;
}

.background {
  background: linear-gradient(174deg, rgba(56,61,61,1) 0%, rgba(0,0,0,1) 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

footer {
  width: 90%;
}

footer p {
  color: #6c757d;
  text-align: center;
}

.description {
  line-height: 2rem;
  text-align: center;
  font-size: 1.4rem;
}

h1 {
  font-size: 3rem;
  margin-bottom: 2rem;
}